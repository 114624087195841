import { Box, Container, Divider, Tab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { WEEKDAYS } from 'Utils/constants/schedule';
import ErrorAlert from 'components/ErrorAlert';
import { ReactComponent as Expand } from 'images/icons/expand.svg';
import { Menu } from 'interfaces/MenuInterface';
import { FullRestaurant } from 'interfaces/ResturantInterface';
import { useApi } from 'providers/ApiProvider';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeastPage } from '../FeastPage';
import TabPanel, { a11yProps } from '../components/TabPanel';
import Tabs from '../components/Tabs';
import { CartContextProvider, useCart } from '../context/CartContext';
import { RestaurantMenuList } from './RestaurantMenuList';
import { DietList } from './TagList';

function RestaurantDetail() {
  const theme = useTheme();
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const [menuSubsections, setMenuSubsections] = useState<any>(null);
  const api = useApi();
  const navigate = useNavigate();
  const sectionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const { cart } = useCart();

  const handleEvent = (val: number) => {
    if (restaurantId) {
      navigate('/explore/'.concat(restaurantId, `/onboarding#${val}`));
    }
  };

  const navigateHome = () => {
    if (restaurantId) {
      navigate('/explore/'.concat(restaurantId));
    }
  };

  function truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  const menuIsAvailable = (menu: Menu) => {
    const date = new Date();
    const currentTime = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    const weekday = [
      WEEKDAYS.SUNDAY,
      WEEKDAYS.MONDAY,
      WEEKDAYS.TUESDAY,
      WEEKDAYS.WEDNESDAY,
      WEEKDAYS.THURSDAY,
      WEEKDAYS.FRIDAY,
      WEEKDAYS.SATURDAY,
    ][new Date().getDay()];

    const serviceAvailability = menu.serviceAvailability.filter(
      (s) => s.day_of_week === weekday
    )[0];
    return (
      menu.active &&
      serviceAvailability.enabled &&
      serviceAvailability.time_periods[0].start_time <= currentTime &&
      serviceAvailability.time_periods[0].end_time >= currentTime
    );
  };

  const getMenu = (restaurant: FullRestaurant) => {
    const availableMenus = restaurant.menus.filter(menuIsAvailable);
    const activeMenus = restaurant.menus.filter((menu) => menu.active);
    if (availableMenus.length === 0) {
      return activeMenus[0];
    }
    return availableMenus[0];
  };

  const {
    selectedAllergies,
    selectedDiets,
    enabledAllergies,
    enabledDiets,
    allergyKeyToName,
    dietKeyToName,
    toggleEnabledAllergy,
    toggleEnabledDiet,
  } = useDietaryPreferences();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    api.getPublicRestaurantData(restaurantId).then((res: any) => {
      console.log('getPublicRestaurantData', res);
      setRestaurant(res);
      setTimeout(() => {
        setLoader(false);
      }, 3000);
      const activeMenu = getMenu(res);
      // Keep all subsections and their items
      setMenuSubsections(
        activeMenu.subsections.map((subsection) => {
          const _menuItems = activeMenu.menuItems.filter((menuItem) =>
            subsection.items.includes(menuItem.id!)
          );
          return {
            ...subsection,
            menuItems: _menuItems,
          };
        })
      );
    });
  }, [enabledAllergies, enabledDiets]);

  const restoNameProps = {
    py: 0.5,
    pl: 1,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark,
    display: 'inline-flex',
  };

  const Header = (data: any) => {
    return (
      <Box sx={{ ...restoNameProps, display: 'flex', alignItems: 'center' }}>
        <img
          alt={data.resto.name}
          src={
            data.resto.imageUrl ||
            (data.resto.imageUrls && data.resto.imageUrls[0]) ||
            (data.resto.image &&
              (typeof data.resto.image === 'string'
                ? data.resto.image
                : URL.createObjectURL(data.resto.image as File))) ||
            ''
          }
          width={120}
          height={60}
          onClick={() => navigateHome()}
          style={{ cursor: 'pointer', marginRight: '50px' }}
        />
        <Typography
          variant='h3'
          color={theme.palette.grey[0]}
          sx={{ textAlign: 'center' }}
        >
          {truncateString(data.resto.name, 20)}
        </Typography>
      </Box>
    );
  };

  const handleTabChange = useCallback((val: number) => {
    if (value !== val) {
      setValue(val);
      sectionRefs.current[val]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [value]);

  // Scroll handler for changing tabs automatically
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          if (index !== -1) {
            setValue(index);
          }
        }
      });
    },
    []
  );

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust as needed (50% visibility)
    });

    sectionRefs.current.forEach((section) => {
      if (section) observerRef.current?.observe(section);
    });

    return () => observerRef.current?.disconnect();
  }, [menuSubsections, handleIntersection]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{ backgroundColor: theme.palette.grey[0], height: '100vh' }}
    >
      <CartContextProvider>
        {loader && <FeastPage />}
        {restaurant && (
          <>
            <Header resto={restaurant} />
            <Container
              sx={{
                mt: 4,
                backgroundColor: theme.palette.grey[0],
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              {selectedDiets.length > 0 && (
                <DietList
                  title='Preferences'
                  diets={selectedDiets}
                  enabledDiets={enabledDiets}
                  dietToName={dietKeyToName}
                  onToggle={toggleEnabledDiet}
                  onEdit={() => handleEvent(1)}
                />
              )}
              {selectedAllergies.length > 0 && (
                <DietList
                  title='Avoided'
                  diets={selectedAllergies}
                  enabledDiets={enabledAllergies}
                  dietToName={allergyKeyToName}
                  onToggle={toggleEnabledAllergy}
                  onEdit={() => handleEvent(2)}
                />
              )}
              {selectedAllergies.length === 0 && selectedDiets.length === 0 && (
                <Box textAlign='right'>
                  <Expand onClick={() => handleEvent(1)} />
                </Box>
              )}
              {menuSubsections && menuSubsections.length > 0 && (
                <Tabs setValue={handleTabChange} value={value}>
                  {menuSubsections.map((subsection: any, index: number) => (
                    <Tab
                      key={index}
                      label={subsection.name}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              )}
              {!menuSubsections || menuSubsections.length === 0 ? (
                <>
                  <Divider />
                  <ErrorAlert error={'No Item found!'} />
                </>
              ) : (
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                  }}
                >
                  <TabPanel value={value} index={value}>
                    {menuSubsections.map((subsection: any, index: number) => {
                      return (
                        <div key={index}>
                          <h3
                            ref={(el) => (sectionRefs.current[index] = el)}
                            style={{ marginBottom: 15, color: "#fe8154" }}
                          >
                            {subsection.name}
                          </h3>
                          <RestaurantMenuList
                            menuItems={subsection.menuItems}
                          />
                          {index < menuSubsections.length - 1 && (
                            <hr style={{ marginBottom: 15 }} />
                          )}
                        </div>
                      );
                    })}
                  </TabPanel>
                </Box>
              )}
            </Container>
          </>
        )}
      </CartContextProvider>
    </Box>
  );
}

export default RestaurantDetail;
