import { Box, Dialog, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import expand from 'images/icons/expand.svg';
import { MenuItem } from 'interfaces/MenuInterface';
import { MenuItemDetail } from 'modules/explorer/MenuItemDetail';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useState } from 'react';
import { useCart } from '../context/CartContext';
import { formatCentsToDollars, getCustomizationOmitsCombined, getEnabledMatchingCustomizations, getMatchingCustomizations } from '../util/util';

interface Props {
  menuItem: MenuItem;
}

export const MenuItemCard = ({ menuItem }: Props) => {
  const { id, title, description, price: initialPrice, menuItemCustomizations, disabled } = menuItem;
  const [price, setPrice] = useState(initialPrice);
  const [isLess, setIsLess] = useState(true);
  const { isInCart, addToCart, removeFromCart } = useCart();
  const { enabledAllergies, enabledDiets } = useDietaryPreferences();

  // Filter customizations based on user preferences
  const filteredCustomizations = getEnabledMatchingCustomizations(menuItemCustomizations, {
    enabledAllergies,
    enabledDiets,
  });



  const { dietMismatchOptions,
    allergyMismatchOptions,
    omitMessages } = getCustomizationOmitsCombined(menuItemCustomizations, enabledAllergies, enabledDiets);

  const combinedOptions = Array.from(new Set([...dietMismatchOptions, ...allergyMismatchOptions]));
  //console.log(omits);

  const useStyles = makeStyles({
    paper: {
      position: "absolute",
      bottom: '-50px',
      width: '100%',
      paddingBottom: '70px',
      borderRadius: '0px!important',
    },
    disabledItem: {
      opacity: 0.5, // Grayed-out for disabled items
      pointerEvents: 'none', // Prevent interaction with disabled items
    },
  });
  const classes = useStyles();

  // Select default options for each customization
  const initialCustomizationOptionIds = filteredCustomizations.reduce(
    (acc, customization) => {
      const { menuItemCustomizationOptions } = customization;
      const defaultModifierIds = menuItemCustomizationOptions
        .filter((option) => option.defaultModifier)
        .map((opt) => opt.id as string);
      return [...acc, ...defaultModifierIds];
    },
    [] as string[]
  );

  const [selectedCustomizationOptionIds, setSelectedCustomizationOptionIds] =
    useState<string[]>(initialCustomizationOptionIds);

  const handleAddItemToOrder = (price: number, quantity: number) => {
    const customizations = filteredCustomizations
      .filter(({ menuItemCustomizationOptions }) =>
        menuItemCustomizationOptions.find(({ id }) =>
          selectedCustomizationOptionIds.includes(id ?? '')
        )
      )
      .map((customization) => ({
        ...customization,
        menuItemCustomizationOptions: getMatchingCustomizations(customization.menuItemCustomizationOptions, {
          enabledAllergies,
          enabledDiets,
        }).filter(({ id }) => selectedCustomizationOptionIds.includes(id ?? '')),
      }));

    addToCart({
      userId: '1',
      item: {
        menuItem,
        customizations,
        price,
        quantity,
        size: quantity,
      },
    });
  };

  const handleOnTapped = (menuItem: MenuItem) => {
    if (isInCart(menuItem)) {
      removeFromCart({
        userId: '1',
        item: {
          menuItem,
          customizations: [],
          price: 0,
          quantity: 0,
          size: 0,
        },
      });
    }
  };

  const dialogProperties = {
    bottom: '-20%',
    height: '90%',
    width: '100%',
    top: 'auto',
  };

  return (
    <>
      <Stack key={id} direction="column" className={disabled ? classes.disabledItem : ''}>
        <Stack
          direction="row"
          alignItems="top"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (!disabled) {
              setIsLess(!isLess);
            }
          }}
        >
          {/* <MenuItemImage menuItem={menuItem} /> */}
          <Stack alignItems="start">
            <Typography fontWeight={700} variant="subtitle1" color={disabled ? 'gray' : 'black'}>
              {title}
            </Typography>
            <Typography variant="caption" color={disabled ? 'gray' : 'black'}>
              {truncateString(description ?? '', 500)}
            </Typography>
            <br />
            <Typography variant="caption" color={disabled ? 'gray' : 'black'}>
              {combinedOptions.length > 0 ? (
                <>
                  <p>
                    <b>Omit:</b> {combinedOptions.join(', ')}. <br /><br />
                   
                      Click <img
                        src={expand}
                        style={{ maxWidth: '10px', marginTop: '0', verticalAlign: 'middle', display: 'inline-block', cursor: disabled ? 'not-allowed' : 'pointer' }}
                        onClick={() => !disabled && handleOnTapped(menuItem)}
                        alt="expand icon"
                      /> to customize this dish as per your needs.
                    
                  </p>
                </>
              ) : null}

            </Typography>

          </Stack>
          <Box sx={{ flex: 1 }} />
          <Stack spacing={1} alignItems="center">
            <Typography fontWeight={700} variant="subtitle1" color={disabled ? 'gray' : 'black'}>
              {formatCentsToDollars(price)}
            </Typography>
            <img
              src={expand}
              style={{ maxWidth: '30px', marginTop: 30, cursor: disabled ? 'not-allowed' : 'pointer' }}
              onClick={() => !disabled && handleOnTapped(menuItem)}
            />
          </Stack>
        </Stack>

        <Dialog
          classes={{ paper: classes.paper }}
          open={!isLess}
          onClose={() => setIsLess(true)}
        >
          <MenuItemDetail
            menuItem={menuItem}
            onClose={() => setIsLess(true)}
            selectedCustomizationOptionIds={selectedCustomizationOptionIds}
            setSelectedCustomizationOptionIds={setSelectedCustomizationOptionIds}
            filteredCustomizations={filteredCustomizations}
            handleAddItemToOrder={handleAddItemToOrder}
            setCurrentPrice={setPrice}
          />
        </Dialog>
      </Stack>
      <Divider />
    </>
  );
};

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}
