import { MenuItemCustomization, MenuItemCustomizationOption } from "interfaces/MenuInterface";
import { Item } from "../model/menu";
import { Preference } from "../model/preference";

interface Image {
  imageUrl?: string;
  imageUrls?: string[];
}
export const getImageUrl = ({ imageUrl, imageUrls }: Image) => {
  if (imageUrl) {
    return imageUrl;
  }

  if (imageUrls && imageUrls.length > 0) {
    return imageUrls[0];
  }

  return 'https://picsum.photos/1125/552';
};

const substituteOmmitedOptions:any = {};
const omittedOptions:any = {};
const substituteAddedOptions:any = {};
const addOptions:any = {};

export function formatCentsToDollars(value: number) {
  const a = (value + '').replace(/[^\d.-]/g, '');
  const b = parseFloat(a);
  return `$${(b ? b / 100 : 0).toFixed(2)}`;
}

export function checkValidEmail(email: string) {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(email); // true
}
export function checkValidPhone(phoneNumber:string) {
  // Remove any non-numeric characters from the phone number
  const cleanNumber = phoneNumber.replace(/\D/g, '');

  // Define a regular expression pattern to check for a valid phone number
  // You may need to customize this pattern based on your country's format
  const pattern = /^\+?[1-9]\d{1,14}$/;

  // Check if the phone number matches the pattern
  return pattern.test(cleanNumber);
}


/**
 * Filter the appropriate menu items for the user base on the user's diets and allergies
 * @param user
 * @param items
 */
export const getMatchingCustomizations = <T extends Item>(
  items: T[],
  { enabledAllergies, enabledDiets }: Preference
): T[] => {
  let filteredItems = [...items];
  // If user has diets, filter the subset
  
  // If user has diets, filter the subset
      if (enabledDiets && enabledDiets.length > 0){
        // if every userDiet is in the list of supported diets show it
        filteredItems = filteredItems.filter(({ diets = [], additionalDiets = [], components =[], menuItemCustomizations = [], title }) => {
          if(menuItemCustomizations && menuItemCustomizations.length > 0) {
            for(let j = 0; j < menuItemCustomizations.length; j++) {
              const customEle = menuItemCustomizations[j];
                // if(customEle && customEle.minPermitted > 0 && !(enabledDiets.every((key) => customEle.diets.includes(key)))) {
                //   return false;
                // } else 
                if(customEle && customEle.minPermitted > 0  && customEle.menuItemCustomizationOptions.length > 0) {
                 
                  let boolFound = false;
                  let optionsCount = 0;
                  for(let l = 0; l < customEle.menuItemCustomizationOptions.length; l++) { 
                    const cusOptionEle = customEle.menuItemCustomizationOptions[l];
                    if(
                      (enabledDiets.every((key) => cusOptionEle.diets.includes(key))) 
                      && !(enabledAllergies.length > 0 && enabledAllergies.some((key) => cusOptionEle.allergies.includes(key)))
                    ) {
                      optionsCount++;
                      boolFound = true;
                    }
                  }
                  if (!boolFound || optionsCount < customEle.minPermitted) {
                    return false;
                  }
                }
            }
          }
          if(components && components.length > 0) {
            for(let i = 0; i < components.length; i++) {
              const component = components[i];
              if(component.required && component.customizations.length === 0 && component.diets && component.diets.length === 0) {
                return false;
              } else if (component.required && component.customizations.length === 0 && component.diets && component.diets.length > 0 && !(enabledDiets.every((key) => component.diets.includes(key)))) {
                return false;
              }
            }
          }
          if (!diets.length) return true; // no diets/additionalDiets are set, it supports all diets
          const itemDiets = [...diets];
          const res = enabledDiets.every((key) => itemDiets.includes(key));
          return res;
        })
      }

      // If user has allergies, filter the subset
      if (enabledAllergies && enabledAllergies.length > 0){
          // if there's one match / intersection for the allergy hide it
          filteredItems = filteredItems.filter(({ allergies = [], additionalAllergies = [], menuItemCustomizations = [] }) => {
            // console.log('itemAllergies', itemAllergies)
            if(menuItemCustomizations && menuItemCustomizations.length > 0) {
              for(let j = 0; j < menuItemCustomizations.length; j++) {
                const customEle = menuItemCustomizations[j];
              }
            }
            const itemAllergies = [...allergies];
            if (!allergies.length) return true; // no allergies/additionalAllergies are set, it supports all allergies
            const res = enabledAllergies.find(key => itemAllergies.includes(key)) == undefined;
            return res;
          })
      }
  return filteredItems;
};
export const getCustomizationOmitsCombined = (
  customizations: MenuItemCustomization[],
  enabledAllergies: string[],
  enabledDiets: string[],
  isHtml = false // New parameter to switch between plain text and HTML output
): { dietMismatchOptions: string[]; allergyMismatchOptions: string[]; omitMessages: string[] } => {
  const dietMismatchOptions: Set<string> = new Set();
  const allergyMismatchOptions: Set<string> = new Set();

  // Iterate through each customization and check its options
  customizations.forEach((customization) => {
    customization.menuItemCustomizationOptions.forEach((option) => {
      // Check if the option matches the user's diets
      if (enabledDiets.length > 0 && option.diets) {
        const missingDiets = enabledDiets.filter((diet) => !option.diets!.includes(diet));
        if (missingDiets.length > 0) {
          if(option.defaultModifier){
          dietMismatchOptions.add(option.title);
          }
        }
      }

      // Check if the option matches the user's allergies
      if (enabledAllergies.length > 0 && option.allergies) {
        const conflictingAllergies = enabledAllergies.filter((allergy) => option.allergies!.includes(allergy));
        if (conflictingAllergies.length > 0) {
          if(option.defaultModifier){
          allergyMismatchOptions.add(option.title);
          }
        }
      }
    });
  });

  // Build a combined message for all customizations
  const omitMessages: string[] = [];

  // Add diet mismatch message, if any
  if (dietMismatchOptions.size > 0) {
    const dietMessage = isHtml
      ? `Omit (diets): ${Array.from(dietMismatchOptions).join(', ')}.<br/><br/>`
      : `Omit (diets): ${Array.from(dietMismatchOptions).join(', ')}.\n\n`;
    omitMessages.push(dietMessage);
  }

  // Add allergy mismatch message, if any
  if (allergyMismatchOptions.size > 0) {
    const allergyMessage = isHtml
      ? `Omit (allergens): ${Array.from(allergyMismatchOptions).join(', ')}.<br/><br/>`
      : `Omit (allergens): ${Array.from(allergyMismatchOptions).join(', ')}.\n\n`;
    omitMessages.push(allergyMessage);
  }

  // Return the options and messages
  return {
    dietMismatchOptions: Array.from(dietMismatchOptions),
    allergyMismatchOptions: Array.from(allergyMismatchOptions),
    omitMessages,
  };
};




/**
 * Filter the appropriate menu items for the user based on the user's diets and allergies.
 * Mark items and customizations as disabled instead of removing them.
 * @param items - The list of items to filter.
 * @param enabledAllergies - The user's enabled allergies.
 * @param enabledDiets - The user's enabled diets.
 * @returns The updated items with disabled property added where necessary.
 */
export const getEnabledMatchingCustomizations = <T extends Item>(
  items: T[],
  { enabledAllergies, enabledDiets }: Preference
): T[] => {
  const updatedItems = [...items];

  // Iterate over each item and mark its components and customizations as disabled
  updatedItems.forEach(item => {
    const { diets = [], additionalDiets = [], components = [], menuItemCustomizations = [] } = item;

    // Handle menu item customizations
    if (menuItemCustomizations && menuItemCustomizations.length > 0) {
      menuItemCustomizations.forEach(customization => {
        customization.disabled = false; // Reset disabled state initially

        // Check if the customization options match the user's diets and allergies
        customization.menuItemCustomizationOptions.forEach((option: MenuItemCustomizationOption) => {
          const matchesDiets = enabledDiets.length === 0 || (option.diets && enabledDiets.every(diet => option.diets!.includes(diet)));
          const matchesAllergies = enabledAllergies.length === 0 || !(option.allergies && enabledAllergies.some(allergy => option.allergies!.includes(allergy)));

          // Mark the option as disabled if it doesn't match the diets or allergies
          option.disabled = !(matchesDiets && matchesAllergies);
        });

        // Mark the entire customization as disabled if none of its options are valid
        const validOptions = customization.menuItemCustomizationOptions.filter((option: MenuItemCustomizationOption) => !option.disabled); // Add the type annotation here
        customization.disabled = validOptions.length === 0;
      });
    }

    // Handle item components based on diets
    if (components && components.length > 0) {
      components.forEach(component => {
        component.disabled = false; // Reset disabled state initially

        // If the component has required diets, check if it matches the enabled diets
        if (component.required && component.diets && component.diets.length > 0) {
          const matchesDiets = enabledDiets.every(diet => component.diets!.includes(diet));
          component.disabled = !matchesDiets;
        }
      });
    }

    // Mark the entire item as disabled if it doesn't match the user's diets or allergies
    if (diets.length > 0) {
      const itemDiets = [...diets, ...additionalDiets];
      item.disabled = !enabledDiets.every(diet => itemDiets.includes(diet));
    } else {
      item.disabled = false; // Reset disabled state if no diets are set
    }
  });

  return updatedItems;
};
